import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import NewSubscription from "./components/NewSub";
import Success from "./components/Success";
import TopUpSub from "./components/TopUp"
import UssdSub from "./components/UssdSub";


function App() {
  return (
    <div className="App max-w-7xl mx-auto h-[100vh]">
      <Header />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/ussd" element={<UssdSub />} />
        <Route exact path="/newsubscription" element={<NewSubscription />} />
        <Route exact path="/topup" element={<TopUpSub />} />
        <Route exact path="/success" element={<Success />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
