import {RiSecurePaymentFill, RiWallet3Line} from "react-icons/ri"
import {MdMobileScreenShare} from "react-icons/md"

export const HomeList = [
	{
		id: 1,
		title: "New Subscription",
		icon: RiSecurePaymentFill,
		description:
			"Do you want to pay for new subscription? please click the button below to proceed.",
		buttonText: "Get Started",
		bgColor: "#607EAA",
		path: "/newSubscription",
	},
	{
		id: 2,
		title: "Top Up",
		icon: RiWallet3Line,
		description:
			"Do you want to top-up your existing subscription? please click the button below to proceed.",
		buttonText: "Get Started",
		bgColor: "#5BB318",
		path: "/topup",
	},
	{
		id: 3,
		title: "USSD",
		icon: MdMobileScreenShare,
		description:
			"Already generated payment reference via USSD? please click the button below to proceed.",
		buttonText: "Get Started",
		bgColor: "#E45826",
		path: "/ussd",
	},
];
