import { MdPerson, MdFamilyRestroom } from "react-icons/md"



export const SubList = [
  {
    icon: MdFamilyRestroom,
    title: "FAMILY PACKAGE",
    color: "blue",
    tabNum: 1,
  },
  {
    icon: MdPerson,
    title: "SINGLE PACKAGE",
    color: "green",
    tabNum: 2,
  },
];
