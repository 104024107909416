import React, { useState } from "react";
import { SubList } from "./SubData";
import { Divider } from "@mui/material";
import FamPackage from "./FamPackage";
import SinglePack from "./SinglePack";

const SubIndex = () => {
	const [tabIndex, setTabIndex] = useState(1);
	const [selected, setSelected] = useState(1);

	return (
		<section className="mx-auto w-full my-6 max-w-4xl" align="center">
			<Divider />
			<div className="flex items-center justify-between my-1">
				{SubList.map((item, index) => {
					return (
						<div
							key={index}
							className="w-full h-auto pr-2 group transition-transform hover:scale-105 duration-500 ease-in-out"
							onClick={() => setSelected(item.tabNum)}
						>
							<p
								onClick={() => setTabIndex(item.tabNum)}
								style={
									selected === item.tabNum
										? {
												backgroundColor: "#E8E8E8",
										  }
										: null
								}
								className="mx-auto p-1 flex items-center justify-center py-4 gap-x-2 cursor-pointer"
							>
								<item.icon className="text-xl" style={{ color: item?.color }} />
								<span className="text-sm text-black font-semibold">
									{item.title}
								</span>
							</p>
						</div>
					);
				})}
			</div>
			<Divider />
			<div>
				{tabIndex === 1 && <FamPackage />}
				{tabIndex === 2 && <SinglePack />}
			</div>
		</section>
	);
};

export default SubIndex;
