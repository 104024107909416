
import React, { useState, useEffect } from "react";
import { Button, Divider } from "@mui/material";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const UssdSub = () => {
    //baseUrl
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [payReference, setPayReference] = useState("");
    const [buttonText, setButtonText] = useState("Checkout");
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (payReference.length !== 13) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [buttonText, payReference]);

    //checkout action
    const onSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Please wait...");
        const token = process.env.REACT_APP_TOKEN;

        const params = JSON.stringify({
            paymentref: payReference,
        });

        try {
            const response = await Axios.post(`${baseUrl}/api/v1/idlcoralpay/verge/paybypaymentref`, params, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            });

            if (response.data.status === true) {
                window.location.href = response.data.data.PayPageLink; // Redirect to the provided PayPageLink
            } else {
                toast.error(`${response.data.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    hideProgressBar: true,
                });
            }

            setPayReference("");
            setIsDisabled(true);
            setButtonText("Checkout");
        } catch (error) {
            console.log(error);
            setButtonText("Checkout");
        }
    };

    return (
        <div className="mx-auto max-w-2xl my-36 lg:my-24" align="center">
            <ToastContainer />

            <form onSubmit={onSubmit} className="mt-[2rem] border-y-2 border-green-500 p-5">
                <div className="bg-gray-200 my-2 py-1">
                    <h3 className="text-xl font-cant font-semibold tracking-wider py-1">USSD</h3>
                </div>

                <Divider />

                <label className="flex flex-col space-x-3 mb-4 mt-3">
                    <span className="text-gray-700 text-sm flex-auto ml-5" align="left">Payment Reference</span>
                    <input
                        required
                        className="shadow border rounded py-2 px-3 form-input mt-1 block w-full ring-green-300 outline-none focus:ring"
                        placeholder="Enter Payment Reference Number"
                        type="text"
                        value={payReference}
                        onChange={(e) => setPayReference(e.target.value)}
                    />
                </label>

                <div className="mx-auto">
                    <Button
                        disabled={isDisabled}
                        type="submit"
                        variant="contained"
                        sx={{
                            width: "70%",
                            backgroundColor: "#00B4D8",
                        }}
                    >
                        {buttonText}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default UssdSub;
