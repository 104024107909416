
import React, { useState, useEffect } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Button from "@mui/material/Button";
import axios from "axios";

const Success = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
         const id = queryParameters.get ("id")

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://safe-payy.herokuapp.com/coralpay/pos/querytransaction/paymentReference/${id}`); 
                setData(response.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchData();
    }, []);

    const redirectToDashboard = () => {
        window.location.href = "/dashboard";
    };

    return (
        <div className="mx-auto max-w-4xl my-48 lg:my-38" align="center">
            <div className="flex flex-col px-10 max-w-2xl mx-auto mb-5 shadow-lg">
                <h3 className="py-8 text-lg lg:text-3xl text-green-500 font-cant font-semibold">
                    <span>
                        <TaskAltIcon />
                    </span>
                    {data ? data.status : error ? "Error occurred while fetching data" : "Loading..."}
                    Transaction Successful
                </h3>

                <Button variant="contained" color="primary" onClick={redirectToDashboard}>
                    Back To Dashboard
                </Button>
            </div>
        </div>
    );
};

export default Success;
