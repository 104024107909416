import React from "react";
import Img from "../../src/images/PLASCHEMA.jpg";
import { useNavigate } from "react-router-dom";
import { GrNotification } from "react-icons/gr";
import { IconButton } from "@mui/material";

const Header = () => {
	const navigate = useNavigate();

	return (
		<header className="flex items-center justify-between p-2 max-w-7xl mx-auto bg-[#4CBC14]">
			<div>
				<img
					className="w-28 lg:w-36 object-contain cursor-pointer rounded-md"
					src={Img}
					alt="logo"
					onClick={(e) => {
						e.preventDefault();
						navigate("/");
					}}
				/>
			</div>
			<div>
				<h3 className="lg:text-3xl font-semibold font-pop text-white tracking-wider">
					PLASCHEMA Subscription Portal
				</h3>
			</div>

			<div>
				<IconButton>
					<GrNotification className="" />
				</IconButton>
			</div>
		</header>
	);
};

export default Header;
