import React, { useState, useEffect } from "react";
import { Button, Divider } from "@mui/material";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const amountValue = [
	{ key: 1, value: "One Month" },
	{ key: 2, value: "Two Months" },
	{ key: 3, value: "Three Months" },
	{ key: 4, value: "Four Months" },
	{ key: 5, value: "Five Months" },
	{ key: 6, value: "Six Months" },
	{ key: 7, value: "Seven Months" },
	{ key: 8, value: "Eight Months" },
	{ key: 9, value: "Nine Months" },
	{ key: 10, value: "Ten Months" },
	{ key: 11, value: "Eleven Months" },
	{ key: 12, value: "Twelve Months" },
];

const SinglePack = () => {
	//baseUrl
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const [formState, setFormState] = useState({
		name: "",
		email: "",
		phone: "",
	});
	const [selectedItem, setSelectedItem] = useState(0);
	const [buttonText, setButtonText] = useState("Checkout");
	const [isDisabled, setIsDisabled] = useState(true);

	useEffect(() => {
		if (
			!formState.name ||
			!selectedItem ||
			formState.phone.length < 11 ||
			formState.phone.length > 11
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [formState.name, formState.phone, selectedItem]);

	//checkout
	const onSubmit = async (e) => {
		e.preventDefault();
		setButtonText("Please wait...");
		const token = process.env.REACT_APP_TOKEN;

		const params = JSON.stringify({
			customer_name: formState.name,
			customer_phone: formState.phone,
			customer_email: formState.email,
			merchant_id: "033",
			duration: selectedItem,
			amount: selectedItem * 1000,
			callback_url: "https://webpay.plateauhealth.online/success",
		});

		await Axios(`${baseUrl}/api/v1/idlcoralpay/verge/invokepayment`, {
			method: "POST",
			data: params,
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		})
			.then((res) => {
				if (res.data.status === true) {
					window.location.href = res.data.data.PayPageLink;
					setButtonText("Checkout");
				} else {
					setButtonText("Checkout");
					toast.error("🤦‍♂️ Something went wrong.", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: true,
					});
				}
				setFormState({
					name: "",
					email: "",
					phone: "",
				});
				setIsDisabled(true);
			})
			.catch((err) => {
				console.log(err);
				setButtonText("Checkout");
			});
	};

	return (
		<div className="mx-auto max-w-2xl" align="center">
			<ToastContainer />

			<form
				onSubmit={onSubmit}
				className="mt-[2rem] border-y-2 border-green-500 p-5"
			>
				<div className="bg-gray-200 my-2 py-1">
					<h3 className="text-xl font-cant font-semibold tracking-wider py-1">
						SINGLE PACKAGE
					</h3>
				</div>

				<Divider />

				<label className="flex flex-col space-x-3 mb-4 mt-3">
					<span className="text-gray-700 text-sm flex-auto ml-5" align="left">
						Name{" "}
					</span>
					<input
						required
						className="shadow border rounded py-2 px-3 form-input mt-1 block w-full ring-green-300 outline-none focus:ring"
						placeholder="Enter Full Name"
						type="text"
						value={formState.name}
						onChange={(e) =>
							setFormState({ ...formState, name: e.target.value })
						}
					/>
				</label>
				<label className="flex flex-col space-x-3 mb-4 mt-3">
					<span className="text-gray-700 text-sm flex-auto ml-5" align="left">
						Email{" "}
					</span>
					<input
						required
						className="shadow border rounded py-2 px-3 form-input mt-1 block w-full ring-green-300 outline-none focus:ring"
						placeholder="Enter Email"
						type="text"
						value={formState.email}
						onChange={(e) =>
							setFormState({ ...formState, email: e.target.value })
						}
					/>
				</label>
				<label className="flex flex-col space-x-3 mb-4 mt-3">
					<span className="text-gray-700 text-sm flex-auto ml-5" align="left">
						Phone Number{" "}
					</span>
					<input
						required
						className="shadow border rounded py-2 px-3 form-input mt-1 block w-full ring-green-300 outline-none focus:ring"
						placeholder="Enter Phone Number"
						type="text"
						value={formState.phone}
						onChange={(e) =>
							setFormState({ ...formState, phone: e.target.value })
						}
					/>
				</label>
				<label className="flex flex-col space-x-3 mb-4 mt-3">
					<span className="text-gray-700 text-sm flex-auto ml-5" align="left">
						Duration{" "}
					</span>
					<select
						required
						className="form-select appearance-none
            shadow border rounded py-2 px-3 form-input mt-1 block w-full ring-green-300 outline-none focus:ring"
						aria-label="Default select example"
						value={selectedItem}
						onChange={(e) => setSelectedItem(e.target.value)}
					>
						<option value="">Select Duration</option>
						{amountValue.map((item, idx) => (
							<option value={item.key} key={idx}>
								{item.value}
							</option>
						))}
					</select>
				</label>

				<label className="flex flex-col space-x-3 mb-4 mt-3">
					<span className="text-gray-700 text-sm flex-auto ml-5" align="left">
						Amount{" "}
					</span>
					<input
						className="shadow border rounded py-2 px-3 form-input mt-1 block w-full ring-green-300 outline-none focus:ring"
						placeholder="Amount"
						type="text"
						id="amount"
						value={selectedItem === 0 ? 0 : selectedItem * 1000}
						readOnly
					/>
				</label>

				<div className="mx-auto">
					<Button
						disabled={isDisabled}
						type="submit"
						variant="contained"
						sx={{
							width: "70%",
							backgroundColor: "#00B4D8",
						}}
					>
						{buttonText}
					</Button>
				</div>
			</form>
		</div>
	);
};

export default SinglePack;
