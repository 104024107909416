import React from "react";

const Footer = () => {
  return (
     <footer className=" text-sm items-center justify-center text-center text-black font-pop font-semibold p-2 max-w-7xl mx-auto h-10 my-3">
        <p>
          {"Copyright © "}
          Designed for Plateau State Contributory Health Care Management Agency by{" "}
          <a
            className="text-green-500  font-medium"
            href="https://instantdeposit.africa"
          >
            Instant Deposit Ltd.
          </a>{" "}
          All Rights Reserved.
          {new Date().getFullYear()}
          {"."}
        </p>
      </footer>
  );
};

export default Footer;
