import React from "react";
import { HomeList } from "./HomeData";
import { useNavigate } from "react-router-dom";
import { RiSendPlaneFill } from "react-icons/ri";

const Home = () => {
	const navigate = useNavigate();

	return (
		<main className="mx-auto overflow-auto my-[5rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-5 md:gap-6 p-2 lg:p-8">
			{HomeList?.map((item) => (
				<div
					key={item?.id}
					className="shadow-lg h-60 border border-gray-400 rounded-lg cursor-pointer overflow-hidden w-full"
					style={{ backgroundColor: item?.bgColor }}
				>
					<h3 className="bg-white p-3 font-semibold tracking-wider">
						{item?.title}
					</h3>
					<div className="bg-[whitesmoke] h-32 my-4 mx-auto px-4 py-2">
						<item.icon
							style={{ color: item?.bgColor }}
							className="text-4xl my-2 mx-auto"
						/>
						<p>{item?.description}</p>
					</div>
					<div align="center" className="mx-auto">
						<button
							className="flex items-center justify-center gap-x-1 text-sm bg-black text-white w-3/5 py-2"
							onClick={(e) => {
								e.preventDefault();
								navigate(item?.path);
							}}
						>
							{item?.buttonText}
							<span>
								<RiSendPlaneFill className="text-white" />
							</span>
						</button>
					</div>
				</div>
			))}
		</main>
	);
};

export default Home;
